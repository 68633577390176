import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const routes = [{
  //path: '/',
  //name: 'home',
  //component: HomeView
  path: '/',
  name: 'index',
  component: () => import('../views/mainSite/index.vue')
}, {
  path: '/setting',
  name: 'index-setting',
  component: () => import('../views/mainSite/indexSetting.vue')
}, {
  path: '/review',
  name: 'index-review',
  component: () => import('../views/mainSite/indexReview.vue')
}, {
  path: '/report',
  name: 'index-report',
  component: () => import('../views/mainSite/indexReport.vue')
}, {
  path: '/user',
  name: 'userman-index',
  component: () => import('../views/mainSite/user/user-index.vue')
}, {
  path: '/user/detail/:id',
  name: 'userman-detail',
  component: () => import('../views/mainSite/user/user-detail.vue')
}, {
  path: '/user/create',
  name: 'userman-create',
  component: () => import('../views/mainSite/user/user-create.vue')
}, {
  path: '/tivi',
  name: 'tivi-index',
  component: () => import('../views/mainSite/tivi/tivi-index.vue')
}, {
  path: '/tivi/detail/:id',
  name: 'tivi-detail',
  component: () => import('../views/mainSite/tivi/tivi-detail.vue')
}, {
  path: '/tivi/create',
  name: 'tivi-create',
  component: () => import('../views/mainSite/tivi/tivi-create.vue')
}, {
  path: '/chinhanh',
  name: 'chinhanh-index',
  component: () => import('../views/mainSite/chinhanh/chinhanh-index.vue')
}, {
  path: '/chinhanh/detail/:id',
  name: 'chinhanh-detail',
  component: () => import('../views/mainSite/chinhanh/chinhanh-detail.vue')
}, {
  path: '/chinhanh/create',
  name: 'chinhanh-create',
  component: () => import('../views/mainSite/chinhanh/chinhanh-create.vue')
}, {
  path: '/login',
  name: 'login-index',
  component: () => import('../views/auth/login.vue')
}, {
  path: '/register',
  name: 'register-index',
  component: () => import('../views/auth/register.vue')
}, {
  path: '/goiten/:makhoa/:machinhanh/:loai_hienthi',
  name: 'goiten-index',
  component: () => import('../views/mainSite/goiten/goiten.index.vue')
}, {
  path: '/ipwan',
  name: 'ipwan-index',
  component: () => import('../views/mainSite/ipwan/ipwan-index.vue')
}, {
  path: '/ipwan/detail/:ip',
  name: 'ipwan-detail',
  component: () => import('../views/mainSite/ipwan/ipwan-detail.vue')
}, {
  path: '/ipwan/create',
  name: 'ipwan-create',
  component: () => import('../views/mainSite/ipwan/ipwan-create.vue')
},
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
