import { defineStore } from 'pinia'
import router from './../router'
export const useLogin = defineStore('loginId', {
    state: () => ({
        name: '',
        token: '',
        isLogin: false,
        isAdmin: false,
    }),
    actions: {
        onSelectedName(name) {
            this.name = name;
        },
        getisAdmin() {
            if (localStorage.getItem('isAdmin') == 'true')
                return true; else return false;
        },
        // onStatus(status) {
        //     this.isLogin = status;
        // },
        onLogin(status, name, admin) {
            this.name = name;
            this.isLogin = status;
            this.isAdmin = admin;
            if (!status) {
                localStorage.clear();
                router.push({ name: 'login-index' });
            }
        }
    }
})