import { defineStore } from 'pinia'
export const useMenu = defineStore('menuId', {
    state: () => ({
        count: 0,
        name: '',
        token: '',
        isLogin: false,
        isAdmin: true,
        items: [],
    }),
    actions: {
        onSubCount(num) {
            this.count = num + 1;
            //console.log("onSubCount: ", num);
        }, onSelectedName(name) {
            this.name = name;
            //console.log("onSelectedName: ", name);
        }, onLogin(islogin) {
            this.isLogin = islogin;
            //console.log("onLogin: ", islogin);
        }
    }
})